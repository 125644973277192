
body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;  
}

body > #root > div {
  height: 100vh;
}

$bgcolor1: #000814;
$bgcolor2: #001d3d;
$primary1: #ffd60a;
$primary2: #ffc300;
$primary3: #003566;
$fontsizeSmall: 0.5em;
$fontsizeSmaller: 0.75em;
$fontsizeButton: 0.85em;
$fontsize: 1em;
$fontsizeBigger: 1.35em;
$fontsizeBig: 1.5em;

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $bgcolor1;
  color: white;
  font-size: $fontsizeBig;

  h1 {
    margin: 0;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  h4 {
    margin: 0;
  }

  h5 {
    margin: 0;
  }

  h6 {
    margin: 0;
  }

  p {
    margin: 1;
    font-size: $fontsizeSmall;
  }

  a {
    text-decoration: none;
    color: $primary3;
    :visited {
      text-decoration: none;
      color: $primary3;
    }
  }

  span {
    font-size: $fontsize;
  }

  button {
    font-size: $fontsizeButton;
  }

  th {
    font-size: $fontsize;
  }

  td {
    font-size: $fontsize;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container {
    background-color: $bgcolor1;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    padding: 10px;
    max-width: 15em;
    .teams {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 12em;
      .setup {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }
      .singleTeam {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .members {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          text-align: center;
        }
      }
      input {
        font-size: $fontsizeBigger;
      }
    }
    .home {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin: 10px;
        width: 9em;
      }
    }
    .options {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      button {
        margin: 10px;
      }
    }
    .game {
      display: flex;
      height: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .questions {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1em;
        max-width: 80%;
        .link {
          padding-right: 4em;
        }
      }
    }
    .howtoplay {
      .rules {
        h6 {
          margin-bottom: 1em;
        }
        h5 {
          margin-bottom: 1em;
        }
      }
    }
    .private {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      .private-text {
        overflow-y: auto;
        overflow-x: auto;
      }
    }
  }
}
